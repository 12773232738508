













import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { ButtonModule } from '../Button.contracts'

/**
 * Container component for the `ButtonModuleUi`.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ButtonModuleUi>({ name: 'ButtonModuleUi' })
export class ButtonModuleUi extends AbstractModuleUi<ButtonModule> {
  public get alignmentClass (): string | null {
    if (!this.style.justifySelf) {
      return null
    }

    return `btn--justify-${this.style.justifySelf}`
  }

  public get shouldRender (): boolean {
    return this.hasContent && !!this.content.link
  }
}

export default ButtonModuleUi
